<template>
<div>
    <ProjectPageTemplate
        projectTitle="TOMB OF THE PHARAOH"
        :imageSource="require('@/assets/images/projectLinks/pharaoh.png')"
        shortDescription="NOT SUITABLE FOR TOUCHSCREENS - A 3D puzzle game, created using raycasting techniques.  Use up and down arrows to move forward and backward and left and right to turn. The Space bar is used to interact with walls. The goal is to navigate the maze to find the exit."
        longDescription= "This was build using a raycasting map editor that I had created by combining and adding to 3 different projects. This made it a lot faster to create textures and maps, and add events to the map.<br>
                          <br>As an evolution of the work I had done with raycasting, this project detects collisions on the walls of a grid rather than the tiles used previously. This has allowed to fit a larger number of walls into the same space and made it easier to add textures to individual wall sections, instead of having the same texture per column.<br>
                          <br>Using optimisations to the texture render has allowed for 32 x 32 textures to be used in this project. These include: 
                          <ul>
                          <li>Reducing the resolution of the rendered textures at further distances</li>
                          <li>Pre-calculating the casting angle of rays on load rather than per frame</li>
                          <li>Optimising the line drawing to only draw when colour has  changed</li>
                          <li>Combining horizontal and vertical wall collision checks of each ray into one loop and checking each point from closest to furthest.</li>
                          </ul>
                          <br>Another new rendering feature was lighting of the walls and darkening the textures the further away they are from the camera. This does slow the rendering slightly but also hides the reduction of the level of the texture detail at a distance.<br>
                          <br>Outside of the rendering, certain walls can now be interacted with and trigger actions such as opening new paths or adding items."
        :importedLinkData="techLinksData"
    >
    <template v-slot:main-content>
        <div id="content-container">
            <div class="content">
                <Raycaster-Walls/>
            </div>
            <div class="screen-small">
                <UnableToDisplay
                :imageSource="require('@/assets/images/shadowExpand.svg')"
                text="SCREEN SIZE TOO SMALL"
            />
            </div>
      </div>
    </template>
    </ProjectPageTemplate>
</div>

</template>

<script>
import ProjectPageTemplate from "@/components/ProjectPageTemplate";
import RaycasterWalls from '../components/RaycasterWalls.vue';
import UnableToDisplay from "@/components/page_elements/UnableToDisplay.vue"
import techLinks from '@/data/techLinks.js'
export default {
    name: 'RaycasterWall',
    components: {
        ProjectPageTemplate,
        RaycasterWalls,
        UnableToDisplay
    },
    data() {
      return {
        techLinksData: [
          techLinks.javascript,
        ],
      }
    }
}
</script>

<style lang="scss" scoped>
$content-width: 1092px;

#content-container {
  .screen-small {
    display: none;
  }

  @media only screen and (max-width: ($content-width + 1)) {
    width: 100%;
    .screen-small {
      display: flex;
    }
    .content {
      display: none;
    }
  }
}
</style>